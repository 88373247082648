/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import fileSize from 'filesize';

import Icon from '@mui/material/Icon';
import Collapse from '@mui/material/Collapse';
import { DataGrid } from '@mui/x-data-grid';

import Loading from './Loading';

import { useGetFileList, usePostFileLike, fileUploadUrl, useGetFileDownload, useGetFileDownloadMultiple } from '../api';
import humanTimeAgo from '../lib/humanTimeAgo';

function Files(props) {
  const {
    subdomain,
    subject,
  } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isShowNewFileUpload, setIsShowNewFileUpload] = useState(false);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMultipleDownloading, setIsMultipleDownloading] = useState(false);
  const downloadedId = useRef();
  const likedId = useRef();
  const dislikedId = useRef();

  const { isLoading, data } = useGetFileList(subdomain, subject);
  const postFileLike = usePostFileLike(subdomain, subject);
  const getFileDownload = useGetFileDownload(subdomain, subject, setIsDownloading);
  const getFileDownloadMultiple = useGetFileDownloadMultiple(
    subdomain,
    subject,
    setIsMultipleDownloading,
  );

  const files = data?.data.files || [];
  const filesObject = files.map((file, i) => ({
    id: i + 1,
    ...file,
  }));

  const onLike = (params, value) => {
    if (isLikeLoading) {
      return;
    }

    if (value > 1) {
      likedId.current = params.row.createdAt;
    } else {
      dislikedId.current = params.row.createdAt;
    }

    setIsLikeLoading(true);

    postFileLike.mutate({
      createdAt: params.row.createdAt,
      increaseValue: value,
    }, {
      onSettled: () => {
        setIsLikeLoading(false);
      },
    });
  };

  const onFileSelect = (fileIds) => {
    setSelectedFiles(
      filesObject.filter((file) => fileIds.includes(file.id)).map((file) => file.createdAt),
    );
  };

  const onDownload = async (createdAt) => {
    if (isDownloading) {
      return;
    }

    downloadedId.current = createdAt;
    getFileDownload.mutate({ createdAt });
  };

  const onDownloadMultiple = async () =>
    !isMultipleDownloading && getFileDownloadMultiple.mutate({ createdAt: selectedFiles });

  const columns = [
    {
      field: 'fileName',
      headerName: 'Pliki',
      minWidth: 300,
      flex: 3,
      renderCell: (params) => (
        <div className="text-file-compact" title={params.value} onClick={() => onDownload(params.row.createdAt)}>{params.value}</div>
      ),
    },
    {
      field: 'fileUrl',
      headerName: 'Akcja',
      minWidth: 140,
      flex: 0.9,
      sortable: false,
      renderCell: (params) => (
        <div className="center">
          <div className="file-download-wrapper">
            <button
              disabled={isDownloading && downloadedId.current === params.row.createdAt}
              type="button"
              className={`btn btn-primary btn-sm file-download ${isDownloading && 'cursor-disabled'}`}
              onClick={() => onDownload(params.row.createdAt)}
            >
              Pobierz
            </button>
          </div>
        </div>
      ),
    },
    {
      field: 'numberOfDownloads',
      headerName: 'Ilość pobrań',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'numberOfLikes',
      headerName: 'Like',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          <Icon
            className={`material-icons md-dark icon-space-right ${isLikeLoading && 'cursor-disabled'}`}
            onClick={() => onLike(params, 1)}
          >
            thumb_up_alt
          </Icon>
          {params.value}
        </>
      ),
    },
    {
      field: 'numberOfDislikes',
      headerName: 'Dislike',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value}
          <Icon
            className={`material-icons md-dark icon-space-left ${isLikeLoading && 'cursor-disabled'}`}
            onClick={() => onLike(params, -1)}
          >
            thumb_down_alt
          </Icon>
        </>
      ),
    },
    {
      field: 'fileSize',
      headerName: 'Rozmiar',
      minWidth: 130,
      flex: 1,
      valueFormatter: (params) => `${fileSize(params.value)}`,
    },
    {
      field: 'createdAt',
      headerName: 'Utworzono',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => humanTimeAgo(params.value),
    },
  ];

  const renderMultipleDownload = () => (
    <button
      disabled={selectedFiles.length === 0 || isMultipleDownloading}
      type="button"
      className={`btn btn-primary d-flex justify-content-center align-items-center ${isMultipleDownloading && 'cursor-disabled'}`}
      onClick={onDownloadMultiple}
    >
      Pobierz zaznaczone
    </button>
  );

  const scrollToBottom = () => {
    window.scroll({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onAddNewFileToggle = () => {
    setIsShowNewFileUpload((oldIsShowNewFileUpload) => !oldIsShowNewFileUpload);
  };

  const renderNewFileUploadButton = () => (
    <button className="btn btn-primary d-flex justify-content-center align-items-center" type="button" onClick={onAddNewFileToggle}>
      <span className="material-icons md-white">
        add
      </span>
      Dodaj nowe pliki
    </button>
  );

  const renderNewFileUploadContent = () => (
    <Collapse
      in={isShowNewFileUpload}
      onEntered={scrollToBottom}
    >
      <div className="jumbotron">
        <h4>
          Dodaj nowe pliki
          {' '}
          <span className="info-small">(maksymalnie 6 MB każdy)</span>
        </h4>
        <form id="fileForm" method="post" encType="multipart/form-data" action={fileUploadUrl(subdomain, subject)} onSubmit={() => setIsSubmitDisabled(true)}>
          <input type="file" name="spikerFile" id="spikerFile" required multiple />
          <input id="fileSubmit" type="submit" value="Zapisz" disabled={isSubmitDisabled} />
        </form>
        Plik większy niż 6 MB?
        {' '}
        (
        <i>Request too long</i>
        )
        <ul>
          <li><a className="external" href="https://www.ilovepdf.com/compress_pdf" target="_blank" rel="noreferrer">kompresja PDF online</a></li>
          <li><a className="external" href="https://compressjpeg.com/pl/" target="_blank" rel="noreferrer">kompresja JPEG, PNG, GIF online</a></li>
          <li><a className="external" href="https://products.aspose.app/slides/splitter/pptx" target="_blank" rel="noreferrer">PPTX splitter online</a></li>
          <li>Inne formaty można wrzucić jako .zip</li>
        </ul>
        Nieobsługiwany format pliku?
        {' '}
        (
        <i>Internal server error</i>
        )
        <ul>
          <li>Skompresować i wrzucić jako .zip</li>
        </ul>
      </div>
    </Collapse>
  );

  useEffect(() => {
    document.title = `Spiker - ${decodeURIComponent(subject)}`;
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="datagrid-wrapper">
        {/* https://mui.com/api/data-grid/data-grid/ */}
        <DataGrid
          className="small-screen-data-grid"
          rows={filesObject}
          columns={columns}
          autoHeight
          density="compact"
          disableColumnSelector
          pageSize={100}
          checkboxSelection
          sortModel={[{ field: 'createdAt', sort: 'desc' }]}
          sortingOrder={['desc', 'asc']}
          onSelectionModelChange={onFileSelect}
          disableSelectionOnClick
          columnBuffer={columns.length}
        />
      </div>

      <div className="d-flex flex-column flex-sm-row action-buttons small-screen-action-buttons">
        {renderMultipleDownload()}
        {renderNewFileUploadButton()}
      </div>

      {renderNewFileUploadContent()}
    </>
  );
}

Files.propTypes = {
  subdomain: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
};

export default Files;
