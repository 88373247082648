import React from 'react';
import {
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';

import Admin from './Admin';
import Faq from './Faq';
import Logo from './Logo';
import Unit from './Unit';

const getLengthOfPrimaryDomain = (host) => {
  const knownDomains = ['localhost', 'dev.d2efvt416mbh7g.amplifyapp.com', 'master.d2efvt416mbh7g.amplifyapp.com', 'spiker.eu'];

  // eslint-disable-next-line no-restricted-syntax
  for (const domain of knownDomains) {
    if (host.includes(domain)) { return domain.split('.').length; }
  }

  throw new Error(`You are using host that doesn't have a primary domain defined: ${host}`);
};

function AppContent() {
  const { host } = window.location;
  const hostSplit = host.split('.');
  const lengthOfPrimaryDomain = getLengthOfPrimaryDomain(host);

  const isSubdomainFromHost = hostSplit.length > lengthOfPrimaryDomain;
  const subdomainFromQueryParams = new URLSearchParams(useLocation().search).get('subdomain');

  const domain = isSubdomainFromHost ? hostSplit.slice(1, hostSplit.length).join('.') : host;
  const subdomain = isSubdomainFromHost ? hostSplit[0] : subdomainFromQueryParams;

  const isSubdomain = subdomain !== null && subdomain !== '';

  if (subdomain === 'www') {
    window.location.href = `${window.location.protocol}//${domain}`;
  }

  return (
    <>
      <div className="top-bar">
        <a className="btn btn-primary btn-sm" href={`${window.location.protocol}//${domain}`}>FAQ</a>
      </div>

      <nav>
        <Logo renderLongDescription />
      </nav>
      <hr className="no-margin" />

      <div className="content container-fluid">
        {isSubdomain && (
          <Switch>
            <Route path="/">
              <Unit subdomain={subdomain} />
            </Route>
          </Switch>
        )}
        {!isSubdomain && (
          <Switch>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/">
              <Faq />
            </Route>
          </Switch>
        )}
      </div>
    </>
  );
}

export default AppContent;
