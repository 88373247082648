import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownload from 'js-file-download';
import { delay } from 'lodash';

import getFileName from '../lib/getFileName';

const DISABLED_SPINNER_DELAY = 200;
const API_ENDPOINT = 'https://v6eeq31m47.execute-api.eu-central-1.amazonaws.com/Prod';
// const API_ENDPOINT = 'http://127.0.0.1:3000';

const fileUploadUrl = (subdomain, subject) => `${API_ENDPOINT}/file/upload?subdomain=${subdomain}&subject=${subject}&redirect=${encodeURIComponent(window.location.href)}`;

const useGetUnit = (subdomain) => useQuery(
  `getUnit${subdomain}`,
  () => axios.get(`${API_ENDPOINT}/unit?subdomain=${subdomain}`),
  {
    retry: 2,
    refetchOnWindowFocus: false,
  },
);

const useGetFileList = (subdomain, subject) => useQuery(
  `getFiles${subdomain}${subject}`,
  () => axios.get(`${API_ENDPOINT}/files?subdomain=${subdomain}&subject=${subject}`),
  {
    retry: false,
    refetchOnWindowFocus: false,
  },
);

const usePostFileLike = (subdomain, subject) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ createdAt, increaseValue }) => axios.post(
      `${API_ENDPOINT}/file/like?subdomain=${subdomain}&subject=${subject}`,
      JSON.stringify({
        createdAt,
        increaseValue,
      }),
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`getFiles${subdomain}${subject}`);
      },
    },
  );
};

const stopLoading = (setIsLoading) =>
  delay(() => setIsLoading(false), DISABLED_SPINNER_DELAY);

const useGetFileDownload = (subdomain, subject, setIsDownloading) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ createdAt }) => {
      setIsDownloading(true);

      return axios.get(
        `${API_ENDPOINT}/file/download?subdomain=${subdomain}&subject=${subject}&createdAt=${createdAt}`,
        {
          responseType: 'blob',
        },
      );
    },
    {
      onSuccess: (response) => {
        FileDownload(response.data, getFileName(response));

        queryClient.invalidateQueries(`getFiles${subdomain}${subject}`);
      },
      onSettled: () => {
        stopLoading(setIsDownloading);
      },
    },
  );
};

const useGetFileDownloadMultiple = (subdomain, subject, setIsMultipleDownloading) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ createdAt }) => {
      setIsMultipleDownloading(true);

      return axios.get(
        `${API_ENDPOINT}/file/download/multiple?subdomain=${subdomain}&subject=${subject}&createdAt=${createdAt}`,
        {
          responseType: 'blob',
        },
      );
    },
    {
      onSuccess: (response) => {
        try {
          FileDownload(response.data, getFileName(response));

          stopLoading(setIsMultipleDownloading);
          // Alternative download option: Downloading from signed URL
        } catch (err) {
          const fr = new FileReader();

          fr.addEventListener('load', async () => {
            await axios.get(JSON.parse(fr.result).url);
            const link = document.createElement('a');
            link.href = JSON.parse(fr.result).url;
            link.click();

            stopLoading(setIsMultipleDownloading);
          });

          fr.readAsText(response.data);
        }

        queryClient.invalidateQueries(`getFiles${subdomain}${subject}`);
      },
      onError: () => {
        stopLoading(setIsMultipleDownloading);
      },
    },
  );
};

export {
  fileUploadUrl,
  useGetUnit,
  useGetFileList,
  usePostFileLike,
  useGetFileDownload,
  useGetFileDownloadMultiple,
};
