import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/DataGrid.css';

const columns = [
  { field: 'id', headerName: '#', width: 100 },
  { field: 'subject',
    headerName: 'Przedmioty',
    minWidth: 400,
    flex: 1,
    renderCell: (params) => <span className="text-file-compact">{params.value}</span>,
  },
];

export default function Subjects(props) {
  const {
    subdomain,
    subjects,
  } = props;
  const history = useHistory();

  const subjectsObject = subjects
    .map(((subject) => subject.trim()))
    .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    .map((subject, i) => ({ id: i + 1, subject }));

  const onRowClick = (event) => {
    const appendSubdomain = window.location.host.split('.')[0] !== subdomain
      ? `?subdomain=${subdomain}`
      : '';

    history.push(`/subject/${event.row.subject}${appendSubdomain}`);
  };

  useEffect(() => {
    document.title = 'Spiker - platforma wymiany notatek dla studentów';
  }, []);

  return (
    <>
      <div className="datagrid-wrapper">
        {/* https://mui.com/api/data-grid/data-grid/ */}
        <DataGrid
          className="small-screen-data-grid"
          rows={subjectsObject}
          columns={columns}
          autoHeight
          density="compact"
          disableColumnSelector
          pageSize={100}
          checkboxSelection={false}
          disableMultipleSelection
          sortingOrder={['desc', 'asc']}
          onRowClick={onRowClick}
        />
      </div>
    </>
  );
}

Subjects.defaultProps = {
  subjects: [],
};

Subjects.propTypes = {
  subdomain: PropTypes.string.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.string),
};
