import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './index.css';

if (window.location.hostname.includes('spiker.eu')) {
  Sentry.init({
    dsn: 'https://6ac2cc723c5f4c78927875c40f7251a5@o1154923.ingest.sentry.io/6234978',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.15,
  });
// eslint-disable-next-line no-console
} else { console.log(`Sentry was not initialized in this env: ${window.location.hostname}`); }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
