import React from 'react';
import PropTypes from 'prop-types';

import LottieAnimation from './LottieAnimation';

function Logo({
  renderLongDescription,
}) {
  return (
    <div className="d-flex align-items-center">
      <div className="logo-animated-wrapper">
        <LottieAnimation src="https://assets5.lottiefiles.com/packages/lf20_ynsr82zq.json" />
      </div>
      <div className="d-flex async-element">
        <span className="logo noselect">
          Spiker
          <span className="logo-suffix">.eu</span>
        </span>
        {renderLongDescription
          ? (
            <div className="d-flex">
              <div className="d-none d-md-block logo-long-description text-secondary noselect">
                <span className="text-right">Platforma wymiany notatek dla studentów</span>
              </div>
            </div>
          ) : <></>}
      </div>
    </div>
  );
}

Logo.defaultProps = {
  renderLongDescription: false,
};

Logo.propTypes = {
  renderLongDescription: PropTypes.bool,
};

export default Logo;
