/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const Breadcrumbs = ({
  subject,
  unitName,
  faculty,
  organisationShort,
}) => {
  const history = useHistory();

  const navigatePrimaryDomain = (event) => {
    event.preventDefault();
    history.push('/');
  };

  return (
    <header className="margin-bottom small-screen-header">
      <nav style={{ '--bs-breadcrumb-divider': "''" }} aria-label="breadcrumb">
        <ol className="breadcrumb small-screen-breadcrumb">
          <h2>
            {subject ? (
              <li className="breadcrumb-item">
                <a href="/" onClick={navigatePrimaryDomain}>{unitName}</a>
              </li>
            ) : (
              <li className="breadcrumb-item active">
                {unitName}
              </li>
            )}
          </h2>
          {subject && (
            <h2>
              <li className="breadcrumb-item active" aria-current="page">
                {`/ ${decodeURIComponent(subject)}`}
              </li>
            </h2>
          )}
        </ol>
      </nav>
      <h4 className="faculty small-screen-faculty">
        {`${faculty}, ${organisationShort}`}
      </h4>
    </header>
  );
};

Breadcrumbs.defaultProps = {
  subject: undefined,
};

Breadcrumbs.propTypes = {
  subject: PropTypes.string,
  unitName: PropTypes.string.isRequired,
  faculty: PropTypes.string.isRequired,
  organisationShort: PropTypes.string.isRequired,
};

export default Breadcrumbs;
