import React from 'react';
import PropTypes from 'prop-types';

import Loading from './Loading';
import Subjects from './Subjects';
import Breadcrumbs from './Breadcrumbs';

import { useGetUnit } from '../api';
import Files from './Files';

function Unit(props) {
  const {
    subdomain,
  } = props;
  let subject;
  if (window.location.pathname.includes('/subject/')) {
    subject = window.location.pathname.replace('/subject/', '');
  }
  const { isLoading, data } = useGetUnit(subdomain);
  const unit = data?.data;
  const subjects = unit?.subjects;

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && unit && (
        <>
          <Breadcrumbs
            subject={subject}
            unitName={unit.name}
            faculty={unit.faculty}
            organisationShort={unit.organisationShort}
          />

          {subject ? (
            <Files subdomain={subdomain} subject={subject} />
          ) : (
            <Subjects subdomain={subdomain} subjects={subjects} />
          )}
        </>
      )}
      {!isLoading && !unit && (
        <>Ta podstrona nie istnieje.</>
      )}
    </>
  );
}

Unit.propTypes = {
  subdomain: PropTypes.string.isRequired,
};

export default Unit;
