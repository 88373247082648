import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function LottieAnimation({ src }) {
  const [lottieClass, setLottieClass] = useState('hidden');
  const lottieRef = useRef();

  const onLottieLoad = () => {
    setLottieClass('fade-in');
  };

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.addEventListener('load', onLottieLoad);
    }
  }, []);

  return (
    <lottie-player
      ref={lottieRef}
      class={lottieClass}
      src={src}
      background="rgb(238, 238, 238)"
      loop
      autoplay
    />
  );
}

LottieAnimation.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LottieAnimation;
