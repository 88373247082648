import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

function Loading() {
  return (
    <div className="loading-spinner d-flex justify-content-center align-items-center">
      <Loader
        type="Grid"
        color="#3C4858"
        height={50}
        width={50}
      />
    </div>
  );
}

export default Loading;
