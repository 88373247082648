import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Admin() {
  const subdomains = ['pilot', 'demo'].sort();
  const [isHidden, setIsHidden] = useState(true);

  return (
    <>
      <h1>
        Ad
        <span onClickCapture={() => setIsHidden((prev) => !prev)}>m</span>
        in
      </h1>
      <div hidden={isHidden}>
        {subdomains.map((subdomain) => (
          <div key={subdomain} className="d-flex flex-2 justify-content-center">
            <a className="btn btn-primary" href={`${window.location.protocol}//${subdomain}.${window.location.host}`}>{`${subdomain}.${window.location.host}`}</a>
            <Link className="btn btn-primary" to={`/?subdomain=${subdomain}`}>{`${window.location.host}/?subdomain=${subdomain}`}</Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default Admin;
