const humanTimeAgo = (timestamp) => {
  const delta = Math.round((new Date() - timestamp) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;

  let fuzzy = new Date(timestamp).toLocaleDateString();

  if (delta < 30) {
    fuzzy = 'kilka sekund temu';
  } else if (delta < minute) {
    fuzzy = `${delta} sekund temu`;
  } else if (delta < 2 * minute) {
    fuzzy = 'minutę temu';
  } else if (delta < 5 * minute) {
    fuzzy = `${Math.floor(delta / minute)} minuty temu`;
  } else if (delta < hour) {
    fuzzy = `${Math.floor(delta / minute)} minut temu`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = 'godzinę temu';
  } else if (delta < day) {
    fuzzy = `${Math.floor(delta / hour)} godzin temu`;
  } else if (delta < day * 2) {
    fuzzy = 'wczoraj';
  }

  return fuzzy;
};

export default humanTimeAgo;
